




















import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import userManager from "@/services/userManager";
import moment from "moment";

export default Vue.extend({
  data() {
    return {
      tableOptions: {
        attrs: {
          "item-key": "_id",
          "sort-by": "createdTime",
          "sort-desc": true
        },
        content: {
          name: "Cài đặt thưởng ngày",
          urlEnabled: true,
          search: {},
          displayFields: {
            startTime: {
              text: "Ngày bắt đầu",
              sortable: true,
              options: {
                filter: "dateTime"
              }
            },
            endTime: {
              text: "Ngày kết thúc",
              sortable: true,
              options: {
                filter: "dateTime"
              }
            },
            description: {
              text: "Mô tả",
              hidden: true
            },
            createdTime: {
              text: "Ngày tạo",
              sortable: true,
              hidden: true,
              options: {
                filter: "dateTime"
              }
            },
            maximumMktCostPerRevenuePercentage: {
              text: "Max CPQC/DSDK MKT",
              options: {
                transform(value) {
                  return `${value}%`;
                }
              }
            },
            items: {
              text: "Chi tiết"
            },
            action: {}
          },
          topActionButtons: {
            insert: {
              target: {
                dialog: {
                  attrs: {
                    width: "800px"
                  }
                }
              }
            }
          },
          template: {
            itemActionButtons: {
              edit: userManager.checkRole(["admin", "kt"]) && {
                target: {
                  dialog: {
                    attrs: {
                      width: "800px"
                    },
                    ext: {
                      subTitleMaker({ startDate, endDate }) {
                        return `${moment(startDate).format(
                          "YYYY-MM-DD"
                        )} - ${moment(endDate).format("YYYY-MM-DD")}`;
                      }
                    }
                  }
                }
              },
              delete: userManager.checkRole(["admin"]) && {}
            }
          }
        },
        ext: {
          dataProvider: {
            async findAll(options) {
              const { items, count } = await coreApiClient.call(
                "dailyBonusSettings",
                "findAll",
                options
              );
              return [items, count];
            },
            async insert(item) {
              return await coreApiClient.call(
                "dailyBonusSettings",
                "create",
                undefined,
                item
              );
            },
            async update(item) {
              return await coreApiClient.call(
                "dailyBonusSettings",
                "update",
                {
                  id: item._id
                },
                {
                  data: item
                }
              );
            },
            async delete(item) {
              return await coreApiClient.call("dailyBonusSettings", "delete", {
                id: item._id
              });
            }
          },
          default: {
            defaultInputs: {
              startTime: {
                type: "XDateTimePicker",
                attrs: {
                  label: "Thời gian bắt đầu",
                  required: true
                }
              },
              endTime: {
                type: "XDateTimePicker",
                attrs: {
                  label: "Thời gian kết thúc",
                  required: true
                }
              },
              description: {
                type: "textLarge",
                attrs: {
                  label: "Mô tả"
                }
              },
              maximumMktCostPerRevenuePercentage: {
                type: "number",
                attrs: {
                  label: "Max CPQC/DSDK MKT",
                  required: true
                }
              },
              items: {
                type: "XArrayInput",
                attrs: {
                  label: "",
                  xOptions: {
                    content: {
                      itemLabel: item =>
                        `Doanh số ngày ${Vue.filter("currency")(item.goal) ||
                          ""} VNĐ - Thưởng ngày ${Vue.filter("currency")(
                          item.value
                        ) || ""} VNĐ`,
                      itemProperties: {
                        goal: {
                          type: "integer",
                          attrs: {
                            label: "Doanh số ngày",
                            required: true
                          }
                        },
                        value: {
                          type: "integer",
                          attrs: {
                            label: "Thưởng ngày",
                            required: true
                          }
                        }
                      }
                    }
                  }
                }
              }
            },
            insertForm: userManager.checkRole(["admin", "kt"]) && {
              content: {
                fieldNames: [
                  "startTime",
                  "endTime",
                  "description",
                  "maximumMktCostPerRevenuePercentage",
                  "items"
                ]
              }
            },
            editForm: userManager.checkRole(["admin", "kt"]) && {
              content: {
                fieldNames: [
                  "startTime",
                  "endTime",
                  "description",
                  "maximumMktCostPerRevenuePercentage",
                  "items"
                ]
              }
            },
            delete: userManager.checkRole(["admin"]) && {
              confirmDisplayField: "name"
            }
          }
        }
      }
    };
  }
});
